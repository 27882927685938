<template>
  <div class="search-player">
    <input
      type="search"
      :placeholder="$t('home.findPlayer')"
      :value="query"
      @input="onInput"
    />
    <ul class="list" v-show="expanded">
      <li
        v-for="(item,index) in result"
        :key="index"
        @click="$emit('search-function', item)"
      >
      <div v-if="item.First_Name"> 
        <b> {{item.Last_Name}}, {{item.First_Name}}</b> 
        <b v-if="item.Last_Team" style="display: inline;">| {{item.Last_Team}}</b>
      </div>
      <div v-else>
        <b> {{item.Name}} </b>
        <b v-if="item.Short_Name" style="display: inline;">({{item.Short_Name}})</b>
        <b class="team-icon" v-bind:style="{'background-image': `url('${$bucketUrl}/images/teams/${item._id}.png')`}"></b>
      </div>
    </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      query: "",
      result: [],
      expanded: false
    };
  },
  methods: {
    onInput(ev) {
      this.query = ev.target.value;
      if (this.query.length >= 3) {
        this.expanded = true;
        window.addEventListener("click", this.close);
        axios
          .get(this.$apiUrl + "/search/home?search=" + this.query)
          .then(res => {
            this.result = res.data;
          });
      } else {
        this.expanded = false;
        this.result = [];
        window.removeEventListener("click", this.close);
      }
    },
    close(){
        this.expanded = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/main.scss";
.search-player {
  margin-bottom: 15px;
  position: relative;
  input[type="search"] {
    width: 100%;
    font-size: 20px;
    padding: 10px;
  }
  .list {
    position: absolute;
    width: 100%;
    background-color: #eee;
    cursor: pointer;
    li {
      list-style: none;
      padding: 10px;
    }
    li:hover {
      background-color: #ddd;
    }
  }
  .team-icon {
    padding-left: 20px;
  }
}
</style>