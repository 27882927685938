<template>
  <div class="featured-player" @click="$router.push({ path: `/players/${content.Player_id}` });">
   
          <picture>
        <template v-if="content.picture">
          <source
            :srcset="`${$bucketUrl}/images/players/${content.Player_id}/${content.picture}.webp`"
            type="image/webp"
          />
          <source
            :srcset="`${$bucketUrl}/images/players/${content.Player_id}/${content.picture}.png`"
            type="image/png"
          />
          <img
            :src="`${$bucketUrl}/images/players/${content.Player_id}/${content.picture}.png`"
          />
        </template>
        <template v-else>
          <source srcset="@/assets/default-player.png" type="image/png" />
          <img src="@/assets/default-player.png" />
        </template>
      </picture>
    <div class="player-info">
    <div
      class="player-name"
      v-bind:style="{'background-image': `url('${$bucketUrl}/images/teams/${content.Team_id}.png')`}"
    >
      <p class="firstname">{{content.First_Name}}</p>
      <p class="lastname">{{content.Last_Name}}</p>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["content"]
};
</script>

<style lang="scss" scoped>
@import "@/main.scss";
.featured-player {
  margin: 0 7.5px 15px 7.5px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
picture img {
  height: 400px;
  width: 100%;
  object-fit: contain;
  object-position: bottom center;
  display: block;
}
.player-info {
  @extend %secondary-pattern;
}
.player-name {
  color: white;
  font-size: 35px;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  padding: 0.3em;
  line-height: 1em;
  background-size: auto 150%;
  background-repeat: no-repeat;
  background-position: 110% 25%;
  .firstname,
  .lastname {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .firstname {
    font-size: 0.7em;
  }
}
</style>